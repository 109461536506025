<div
  #notificationContainer
  class="surface-ground notifications-container h-full overflow-auto px-2"
>
  <div class="mx-auto max-w-[720px]">
    <div class="linear max mx-auto mb-24 transition-all duration-500">
      <div class="container mb-8 mt-2">
        <div
          class="search-results"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollUpDistance]="2"
          [infiniteScrollThrottle]="500"
          [infiniteScrollContainer]="notificationContainer"
          [fromRoot]="true"
          (scrolled)="onScrolledDown()"
        >
          <p-card
            class="password mx-auto block md:mx-0 md:my-auto xl:w-[680px]"
          >
            <div class="flex justify-between">
              <h2 class="h-level-2">Notifications</h2>
              <button
                (click)="notificationMain.toggle($event)"
                pButton
                class="mx-3 h-[32px] rounded-full p-1"
              >
                <span class="material-icons"> more_horiz </span>
              </button>
            </div>
            <div class="grow-1 mt-2 flex gap-2">
              <p-selectButton
                [options]="selectButtonOptions"
                (onChange)="onSelectAction($event)"
                [(ngModel)]="selectedButton"
                styleClass="mp-selectbutton"
                optionLabel="label"
                optionValue="value"
              ></p-selectButton>
            </div>
            <div
              role="rowheader"
              class="pt-4"
              *ngIf="notificationList.length && !isLoading"
            >
              <h3 class="h-level-3 min-w-0 max-w-full">
                <span>Earlier</span>
              </h3>
            </div>
            <div *ngIf="!isLoading">
              <div
                role="row"
                class="relative -mx-4 rounded-md py-2 transition-all duration-200 md:-mx-0"
                *ngFor="let list of notificationList; let i = index"
                (mouseenter)="mouseEvent(list.id, list.read)"
              >
                <div class="px-1">
                  <div role="gridcell">
                    <a class="min-h-0 cursor-pointer px-0 py-0">
                      <div
                        class="mx-0 my-0 flex min-h-[44px] min-w-0 items-center justify-start py-3 md:py-0"
                      >
                        <div class="mr-4 flex flex-col">
                          <p-avatar size="xlarge" shape="circle">
                            <img
                              *ngIf="
                                list.userFrom && list.userFrom.profilePicUrl
                              "
                              [src]="
                                getProfileImage(list.userFrom.id)
                                  | image
                                  | async
                              "
                              alt=""
                            />
                            <img
                              *ngIf="
                                !list.userFrom || !list.userFrom.profilePicUrl
                              "
                              src="../assets/images/user-pic-default.svg"
                              alt="default"
                            />
                          </p-avatar>
                        </div>
                        <div
                          class="shrink-1 grow-1 flex min-w-0 basis-[80%] flex-col items-stretch justify-between"
                          (click)="redirectToTimeline(list)"
                        >
                          <span
                            [ngClass]="{ unread: !list.read }"
                            class="overflow-hidden text-left"
                            [innerHtml]="list.message"
                          ></span>
                          <span class="primary sub-title">{{
                            moment(list.triggerTime).fromNow()
                          }}</span>
                        </div>
                        <div>
                          <p-badge
                            *ngIf="!list.read"
                            styleClass="h-4 min-w-4"
                          ></p-badge>
                        </div>
                        <div class="flex h-[32px] flex-1">
                          <button
                            (click)="notification.toggle($event)"
                            pButton
                            class="mx-3 h-[32px] h-full rounded-full p-1 shadow-md"
                          >
                            <span class="material-icons"> more_horiz </span>
                          </button>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <p-divider
                  *ngIf="notificationList.length - 1 !== i"
                  styleClass="m-0 mt-3"
                ></p-divider>
              </div>
              <div role="row" *ngIf="!notificationList.length" class="py-6">
                <span *ngIf="selectedButton === 'all'"
                  >No notification found.</span
                >
                <span *ngIf="selectedButton === 'unread'"
                  >No unread notification found.</span
                >
              </div>
            </div>
            <div class="overlay" *ngIf="isLoading">
              <p-progressSpinner
                styleClass="horizontal-center"
              ></p-progressSpinner>
            </div>
          </p-card>
        </div>
      </div>
    </div>
  </div>
</div>

<p-tieredMenu
  #notification
  [model]="notificationOptions"
  appendTo="body"
  [popup]="true"
  styleClass="p-0"
>
  <ng-template pTemplate="p-menuitem" let-obj>
    <div
      class="flex cursor-pointer px-2 py-3 dark:bg-[#1F242F] dark:text-slate-300"
      *ngIf="
        (obj.id !== 'mark_as_unread' && !isRead) ||
        obj.id === 'remove_notification'
      "
      (click)="notificationAction(obj.id)"
    >
      <div class="font-normal">
        {{ obj.label }}
      </div>
    </div>
    <div
      class="flex cursor-pointer px-2 py-3"
      *ngIf="obj.id === 'mark_as_unread' && isRead"
      (click)="notificationAction(obj.id)"
    >
      <div class="font-normal">
        {{ obj.label }}
      </div>
    </div>
  </ng-template>
</p-tieredMenu>

<p-tieredMenu
  #notificationMain
  [model]="notificationMainOptions"
  appendTo="body"
  [popup]="true"
  styleClass="p-0"
>
  <ng-template pTemplate="p-menuitem" let-obj>
    <div
      class="flex cursor-pointer px-2 py-3 dark:bg-[#1F242F] dark:text-slate-300"
      (click)="notificationAction(obj.id)"
    >
      <div class="font-normal">
        {{ obj.label }}
      </div>
    </div>
  </ng-template>
</p-tieredMenu>
