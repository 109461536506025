import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { NotificationsComponent } from './notifications.component';
import { NotificationOverviewComponent } from './notification-overview/notification-overview.component';
import { authGuard } from '../../utilities';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: NotificationsComponent,
    children: [
      {
        path: '',
        component: NotificationOverviewComponent,
      },
      {
        path: 'unread',
        component: NotificationOverviewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationsRoutingModule {}
